import { v3Url } from '@/utils/fetcher';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import useListLoader from '../base/useListLoader';

function responseToItems(response) {
  const users = response.data.included.users;
  const utilizations = response.data.utilization.map((u) => ({
    id: u.userId,
    user: users[u.userId],
    utilizations: u.data,
  }));

  return utilizations;
}

/**
 * Loads a list of utilizations from the Teamwork v3 API.
 */
export default function useUtilizationPeopleLoader({ params, count, pageSize = 50 }) {
  const { state, refresh } = useListLoader({
    url: v3Url('people/utilization'),
    params,
    count,
    responseToItems,
    pageSize,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'person') {
      refresh(event.userId);
    } else if (event.type === 'event') {
      // Necessary for unavailable time updates
      refresh();
    }
  });

  return state;
}
