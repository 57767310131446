import Vue from 'vue';
import moment from 'moment';
import typedDefaults from '@/utils/helpers/typed-defaults';
import { getDatesFromRange } from '@/utils/helpers/date';
import { mapGetters } from '@/store/utils/record-mapper';

const format = 'YYYYMMDD';
const minFilterChars = 3;

const defaultTypes = {
  key: '',
  active: false,
  parameters: {
    filterText: '',
    priority: '',

    responsiblePartyIds: [],
    assigneeCompanyIds: [],
    assigneeTeamIds: [],
    includeAssigneeCompanies: false,
    includeAssigneeTeams: false,

    creatorIds: [],
    tagIds: [],
    excludeTagIds: [],
    filterBoardColumnIds: [],
    stages: [],

    matchAllTags: true,
    matchAllExcludedTags: false,
    onlyUntaggedTasks: false,
    includeBlockedTasks: true,
    includeCompletedTasks: false,
    getSubTasks: true,
    includeOverdueTasks: false,
    includeTasksWithNoDate: false,
    ignoreStartDates: false,
    onlyTasksWithTickets: false,
    onlyTasksWithUnreadComments: false,

    dateCode: '',
    startDate: '',
    endDate: '',
    dateCustomInterval: 0,

    dateCreatedCode: '',
    createdAfterDate: '',
    createdBeforeDate: '',
    createdDateCustomInterval: 0,

    // projectsList Filter fields
    categoryIds: [],
    projectCategoryIds: [],
    projectCompanyIds: [],
    customFields: [],
    projectTagIds: [],
    firstLetter: '',
    hideObservedProjects: false,
    includeDeletedUsers: false,
    includeSubCategories: true,
    onlyProjectsWithExplicitMembership: false,
    matchAllProjectTags: false,
    onlyStarredProjects: false,
    projectHealths: [],
    projectOwnerIds: [],
    searchTerm: '',

    // workloadPeoplePlanner Filter fields
    userIds: [],
    companyIds: [],
    projectIds: [],
    teamIds: [],
  },
};

export default {
  namespaced: true,
  state: {
    records: {},
    sharedFilterHash: '',
    currentFilterKey: '',
    // Special case for Gantt where filters are disabled, but
    // completed tasks can be toggled
    includeCompletedTasks: false,
  },
  getters: {
    ...mapGetters({
      dateRange: (state, getters, rootState, rootGetters) => {
        const params = state && state.parameters;
        if (params && params.dateCode) {
          if (params.dateCode === 'custom') {
            if (params.startDate && params.endDate) {
              return {
                startDate: params.startDate,
                endDate: params.endDate,
              };
            }
          } else if (['thisweek', 'within', 'withinprev'].includes(params.dateCode)) {
            const weekString = rootGetters['user/weekString'];
            return getDatesFromRange(params.dateCode, weekString, format, params.dateCustomInterval);
          } else {
            return { filter: params.dateCode };
          }
        }
        return {};
      },
      createdDateRange: (state, getters, rootState, rootGetters) => {
        const params = state && state.parameters;
        if (params && params.dateCreatedCode) {
          if (params.dateCreatedCode === 'custom') {
            if (params.createdAfterDate && params.createdBeforeDate) {
              return {
                createdAfterDate: moment(params.createdAfterDate, format).utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                createdBeforeDate: moment(params.createdBeforeDate, format)
                  .add(1, 'd')
                  .utc()
                  .format('YYYY-MM-DDTHH:mm:ssZ'),
              };
            }
          } else if (
            [
              'thisweek',
              'lastweek',
              'thismonth',
              'lastmonth',
              'last3months',
              'last6months',
              'within',
              'withinprev',
            ].includes(params.dateCreatedCode)
          ) {
            const weekString = rootGetters['user/weekString'];
            const dates = getDatesFromRange(
              params.dateCreatedCode,
              weekString,
              format,
              params.createdDateCustomInterval,
            );
            return {
              createdAfterDate: dates.startDate,
              createdBeforeDate: dates.endDate,
              createdFilter: 'custom',
            };
          } else {
            return { createdFilter: params.dateCreatedCode };
          }
        }
        return {};
      },
      basicParameters: (state) =>
        Object.keys((state && state.parameters) || {})
          // Date fields dealt with elsewhere
          .filter((k) =>
            [
              'priority',
              'matchAllTags',
              'matchAllExcludedTags',
              'onlyUntaggedTasks',
              'includeBlockedTasks',
              'includeCompletedTasks',
              'getSubTasks',
              'ignoreStartDates',
              'onlyTasksWithTickets',
              'onlyTasksWithUnreadComments',
              'includeAssigneeCompanies',
              'includeAssigneeTeams',
              'matchAllProjectTags',
              'firstLetter',
              'hideObservedProjects',
              'includeDeletedUsers',
              'includeSubCategories',
              'onlyProjectsWithExplicitMembership',
              'onlyStarredProjects',
              'searchTerm',
            ].includes(k),
          )
          .filter((k) => state.parameters[k] !== defaultTypes.parameters[k])
          .reduce((params, k) => ({ ...params, [k]: state.parameters[k] }), {}),

      customFieldFilters: (state) =>
        Object.keys((state && state.parameters) || {})
          .filter((k) => k.includes('ustomField['))
          .reduce((params, k) => ({ ...params, [k]: state.parameters[k] }), {}),

      arrayParameters: (state) =>
        Object.keys((state && state.parameters) || {})
          .filter((k) => Array.isArray(state.parameters[k]) && state.parameters[k].length)
          .reduce((params, k) => ({ ...params, [k]: state.parameters[k].join(',') }), {}),

      filterText: (state) =>
        state && (state.parameters.filterText || '').length >= minFilterChars
          ? { filterText: [state.parameters.filterText] }
          : {},

      keySpecific: (state, getters, rootState, rootGetters, key) => {
        switch (key) {
          case 'projectTasks':
            return {
              include: [
                'taskListNames',
                'projectNames',
                state && state.parameters.includeOverdueTasks && 'overdue',
                state && state.parameters.includeTasksWithNoDate && 'nodate',
              ]
                .filter(Boolean)
                .join(','),
            };
          default:
            return {};
        }
      },

      apiParameters: (state, getters, rootState, rootGetters, key) => ({
        ...getters.basicParameters(key),
        ...getters.arrayParameters(key),
        ...getters.dateRange(key),
        ...getters.createdDateRange(key),
        ...getters.filterText(key),
        ...getters.keySpecific(key),
        ...getters.customFieldFilters(key),
      }),
    }),
    'current/active': (state) => state.records[state.currentFilterKey] && state.records[state.currentFilterKey].active,
    'current/apiParameters': (state, getters) =>
      (state.currentFilterKey && getters.apiParameters(state.currentFilterKey)) || {},
  },
  mutations: {
    record(state, filter) {
      // Normalisation
      if (filter.key === 'projectsList') {
        const params = filter.parameters;
        Object.assign(filter.parameters, {
          projectTagIds: params.filterTagIds,
          matchAllProjectTags: params.matchAllTags,
          projectHealths: params.projectHealth,
          projectCompanyIds: params.companyIds,
          projectCategoryIds: params.categoryIds,
        });
      }

      const newFilter = typedDefaults(defaultTypes, filter);
      Vue.set(state.records, newFilter.key, newFilter);
    },
    currentFilterKey(state, filterKey) {
      state.currentFilterKey = filterKey;
    },
    toggleCompletedTasksForGantt(state) {
      state.includeCompletedTasks = !state.includeCompletedTasks;
    },
    sharedFilterHash(state, hash) {
      state.sharedFilterHash = hash;
    },
  },
};
