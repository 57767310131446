import { render, staticRenderFns } from "./Root.vue?vue&type=template&id=55a08b01&"
import script from "./Root.vue?vue&type=script&lang=js&"
export * from "./Root.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.2_babel-core@7.0.0-bridge.0_@babel+core@7.23.2_supports-color@9.4.0___css-lo_cxymzsvmby4qf2mwggx3a2wt5i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports