import { v3Url } from '@/utils/fetcher';
import useListLoader from '../base/useListLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToMeta({
  data: {
    userProfitabilities: { users, ...profitability },
  },
}) {
  return { profitability };
}

function responseToItems({
  data: {
    userProfitabilities: { users: profitabilityItems = [] },
    included: { users = {} } = {},
  },
}) {
  return profitabilityItems.map(({ user: { id }, ...profitability }) => {
    const user = users[id] || { id };
    user.profitability = profitability;
    return user;
  });
}

export default function useProfitabilityUsersLoader({ count, params, pageSize } = {}) {
  const { state, refresh } = useListLoader({
    url: v3Url('profitability/users'),
    params,
    count,
    pageSize,
    responseToItems,
    responseToMeta,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'project' && event.action === 'rate-set') {
      refresh(event.userId);
    }

    if (event.type === 'time' || (event.type === 'installation' && event.action === 'rate-set')) {
      refresh();
    }
  });

  return state;
}
