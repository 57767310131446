import { v3Url } from '@/utils/fetcher';
import useListLoader from '../base/useListLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItems({ data }) {
  const { milestones } = data;
  const {
    projectCategories = {},
    companies = {},
    projects = {},
    tags = {},
    tasklists = {},
    tasks = {},
    teams = {},
    users = {},
    tasklistTaskStats = {},
  } = data.included;

  /* eslint-disable no-param-reassign */
  milestones.forEach((milestone) => {
    if (milestone.project && projects[milestone.project.id]) {
      milestone.project = projects[milestone.project.id];
    }

    if (milestone.tags && milestone.tags.length && tags) {
      const tgs = [];
      milestone.tags.forEach((tg) => {
        tgs.push(tags[tg.id]);
      });
      milestone.tags = tgs;
    }

    if (milestone.tasklists && milestone.tasklists.length && tasklists) {
      const tskls = [];
      milestone.tasklists.forEach((tl) => {
        tasklists[tl.id].stats = tasklistTaskStats[tl.id] || {};
        tskls.push(tasklists[tl.id]);
      });
      milestone.tasklists = tskls;
    }

    if (milestone.tasks && milestone.tasks.length && tasks) {
      const tsks = [];
      milestone.tasks.forEach((tsk) => {
        tsks.push(tasks[tsk.id]);
      });
      milestone.tasks = tsks;
    }

    if (milestone.project.categoryId && projectCategories) {
      milestone.category = projectCategories[milestone.project.categoryId];
    }

    if (milestone.project.companyId && companies) {
      milestone.company = companies[milestone.project.companyId];
    }

    if (milestone.creatorUserId && users[milestone.creatorUserId]) {
      milestone.createdBy = users[milestone.creatorUserId];
    }

    if (milestone.updatedBy && users[milestone.updatedBy]) {
      milestone.updatedBy = users[milestone.updatedBy];
    }

    if (milestone.completedBy && users[milestone.completedBy]) {
      milestone.completedBy = users[milestone.completedBy];
    }

    if (milestone.responsibleParties && milestone.responsibleParties.length) {
      const result = [];
      milestone.responsibleParties.forEach((item) => {
        switch (item.type) {
          case 'users':
            if (users && users[item.id]) {
              result.push({
                ...users[item.id],
                assigneeType: 'users',
              });
            }
            break;
          case 'companies':
            if (companies && companies[item.id]) {
              result.push({
                ...companies[item.id],
                assigneeType: 'companies',
              });
            }
            break;
          case 'teams':
            if (teams && teams[item.id]) {
              result.push(teams[item.id]);
              result.push({
                ...teams[item.id],
                assigneeType: 'teams',
              });
            }
            break;
          default:
            break;
        }
      });
      milestone.responsibleParties = result;
    }
  });
  /* eslint-enable no-param-reassign */

  return milestones;
}

/**
 * Loads a list of milestones from the Teamwork v3 API.
 */
export default function useMilestonesLoader({ params, count, pageSize } = {}) {
  const { state, refresh, update } = useListLoader({
    url: v3Url('milestones'),
    params,
    count,
    pageSize,
    responseToItems,
  });

  useOptimisticUpdates((event) => {
    if (event.type === 'milestone' && event.action === 'update') {
      update(
        (milestones) =>
          milestones.map((milestone) =>
            milestone.id === event.milestone.id ? { ...milestone, ...event.milestone } : milestone,
          ),
        event.promise,
      );
    }
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'milestone') {
      refresh(event.milestoneId);
    } else if (event.type === 'task') {
      // Refresh only on certain cases when event is 'task'
      if (
        event.action === 'completed' ||
        event.action === 'reopened' ||
        event.action === 'deleted' ||
        event.action === 'new'
      ) {
        refresh();
      }
    }
  });

  return state;
}
