import { computed, inject, provide } from 'vue-demi';
import moment from 'moment';
import { FREE_ACCOUNTS } from '@tko/src/app/helpers/constants';
import { useCurrentAccount, useCurrentUser } from '@/api';
import { useFeatures } from '@/platform/composables/useFeatures';
import { useCurrentProject } from '@/platform/composables/useCurrentProject';
import usePricePlan from './usePricePlan';

const usePermissionsSymbol = Symbol('usePermissions');

function PermissionsService() {
  const account = useCurrentAccount();
  const project = useCurrentProject();
  const user = useCurrentUser();
  const {
    customfieldsProjectsEnabled,
    customfieldsTasksEnabled,
    hideUpgradePathsForMaxpSmallAccountsEnabled,
    projectTemplatesEnabled,
    reportsEnabled,
    projectTimeBudgetsEnabled,
    projectsFeatureTrialScale,
    projectsFixedFeePermissionEnabled,
  } = useFeatures();
  const { isPlanFree, isPlanStarter, isPlanDeliver } = usePricePlan();

  const isFreeAccount = computed(() => Boolean(FREE_ACCOUNTS.includes(account.value?.pricePlanId)));
  const isPlanTypeMaxProjects = computed(() => Boolean(account.value?.priceplanType === 'maxProjects'));

  const shouldShowPlanTrialUpgradeSlates = computed(
    () => !(isPlanFree.value || isPlanStarter.value || isPlanDeliver.value),
  );

  const isInPlanTrialUpgradeSegment = computed(
    () =>
      shouldShowPlanTrialUpgradeSlates.value &&
      !(
        hideUpgradePathsForMaxpSmallAccountsEnabled.value ||
        (!isPlanTypeMaxProjects.value && isFreeAccount.value && moment().diff(account.value?.dateSignedUp, 'days') < 60)
      ),
  );
  const shouldShowNewUpgradePath = computed(
    () => !shouldShowPlanTrialUpgradeSlates.value || projectsFeatureTrialScale.value,
  );
  const isAccount = computed(() => Boolean(user.value?.userType === 'account'));
  const isSiteOwner = computed(() => Boolean(user.value?.inOwnerCompany && user.value?.siteOwner));
  const isAdmin = computed(() => Boolean(user.value?.administrator));
  const isOwnerAccount = computed(() => Boolean(user.value?.inOwnerCompany && user.value?.userType === 'account'));
  const isCollaborator = computed(() => Boolean(user.value?.userType === 'collaborator'));

  const isOwnerAdmin = computed(() => Boolean(user.value?.inOwnerCompany && isAdmin.value));
  const isProjectAdmin = computed(() => Boolean(isAdmin.value || project.value?.permissions.projectAdministrator));
  const canAccessCalendar = computed(() => Boolean(user.value?.permissions.canAccessCalendar));
  const canAccessInvoiceTracking = computed(() =>
    Boolean(isAdmin.value || project.value?.permissions.canAccessInvoiceTracking),
  );
  const canAccessPeopleCompaniesTeams = computed(() =>
    Boolean(isOwnerAccount.value || (user.value?.permissions.canManagePeople && isAccount.value)),
  );
  const canAddProjects = computed(() => Boolean(isAdmin.value || user.value?.permissions.canAddProjects));
  const canAddTasks = computed(() => Boolean(isAdmin.value || project.value?.permissions.canAddTasks));
  const canAddTasklists = computed(() =>
    Boolean(project.value?.status === 'active' && (isAdmin.value || project.value?.permissions.canAddTaskLists)),
  );
  const canViewEstimatedTime = computed(() =>
    Boolean(!isCollaborator.value && project.value?.permissions.viewEstimatedTime),
  );
  const canManageProjectBudget = computed(() =>
    Boolean(projectTimeBudgetsEnabled.value && (isAdmin.value || project.value?.permissions.canManageProjectBudget)),
  );
  const canViewGlobalSettingsTags = computed(() => Boolean(isOwnerAdmin.value && account.value?.tagsEnabled));

  const canViewReportsUpgradeCta = computed(() =>
    Boolean(
      (isInPlanTrialUpgradeSegment.value || shouldShowNewUpgradePath.value) &&
        (isAdmin.value || user.value?.permissions.isAdminOnAProject) &&
        user.value?.inOwnerCompany,
    ),
  );

  const canViewReports = computed(() =>
    Boolean(
      (isAdmin.value || user.value?.permissions.isAdminOnAProject) &&
        (reportsEnabled.value || canViewReportsUpgradeCta.value),
    ),
  );
  const canAccessReportsTab = computed(() => Boolean(canViewReports.value || canViewReportsUpgradeCta.value));

  const canViewGlobalSettingsCustomfields = computed(() =>
    Boolean(
      (customfieldsProjectsEnabled.value || customfieldsTasksEnabled.value) &&
        (isOwnerAdmin.value || user.value?.permissions.canManageCustomFields),
    ),
  );

  const canManageFixedFeeBudgets = computed(
    () =>
      isAdmin.value || (projectsFixedFeePermissionEnabled.value && user.value?.permissions.canManageFixedFeeBudgets),
  );

  const canViewLoggedTime = computed(() => Boolean(isAdmin.value || project.value?.permissions.viewTimeLog));

  const canLogTime = computed(() =>
    Boolean(project.value?.status === 'active' && project.value?.permissions.canLogTime),
  );

  const canViewProjectBudget = computed(() =>
    Boolean(projectTimeBudgetsEnabled.value && (isAdmin.value || project.value?.permissions.canViewProjectBudget)),
  );
  const canViewProjectTemplates = computed(() =>
    Boolean(projectTemplatesEnabled.value && (isAdmin.value || user.value?.permissions.canViewProjectTemplates)),
  );
  const canViewProjectTemplatesUpgradeCta = computed(() =>
    Boolean(
      !projectTemplatesEnabled.value &&
        canAddProjects.value &&
        user.value?.inOwnerCompany &&
        isInPlanTrialUpgradeSegment.value,
    ),
  );
  const canViewTasks = computed(() => Boolean(project.value?.permissions.viewTasksAndMilestones));

  const canViewRates = computed(() => Boolean(project.value?.permissions.canViewRates));
  const canManageRates = computed(() => Boolean(project.value?.permissions.canManageRates));
  const canManageCostRates = computed(() => Boolean(isOwnerAdmin.value || isSiteOwner.value));

  return {
    isAccount,
    isSiteOwner,
    isAdmin,
    isCollaborator,
    isInPlanTrialUpgradeSegment,
    isOwnerAccount,
    isOwnerAdmin,
    isProjectAdmin,
    canAccessCalendar,
    canAccessReportsTab,
    canAccessInvoiceTracking,
    canAccessPeopleCompaniesTeams,
    canAddProjects,
    canAddTasklists,
    canAddTasks,
    canManageProjectBudget,
    canViewEstimatedTime,
    canViewGlobalSettingsCustomfields,
    canViewGlobalSettingsTags,
    canViewLoggedTime,
    canLogTime,
    canViewProjectBudget,
    canViewProjectTemplates,
    canViewProjectTemplatesUpgradeCta,
    canViewTasks,
    canViewReportsUpgradeCta,
    canViewReports,
    canViewRates,
    canManageRates,
    canManageCostRates,
    shouldShowPlanTrialUpgradeSlates,
    canManageFixedFeeBudgets,
  };
}

export function providePermissions() {
  provide(usePermissionsSymbol, PermissionsService());
}

/**
 *
 * @returns {PermissionsService}
 */
export function usePermissions() {
  return inject(usePermissionsSymbol);
}
