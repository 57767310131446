import { onUnmounted } from 'vue-demi';
import store from '@/store';
/**
 * Watched the recency store and submits new Recents to API
 */
export default function useRecencyProcessor() {
  const stopWatchingViews = (function watchViews() {
    return store.watch(
      (state) => state.recency.views,
      () => {
        store.dispatch('recency/processRecents');
      },
    );
  })();

  const stopWatchingEntities = (function watchEntities() {
    return store.watch(
      (state) => state.recency.entities,
      () => {
        store.dispatch('recency/processRecents');
      },
      { deep: true },
    );
  })();

  const stopWatchingCurrentProject = (function watchCurrentProject() {
    // returns function to stop the vuex watcher
    return store.watch(
      () => store.getters['project/current'],
      (project) => {
        if (!project) {
          return;
        }
        store.dispatch('recency/logEntity', {
          description: project.name,
          entityId: project.id,
          entityType: 'project',
          projectId: project.id,
        });
      },
    );
  })();

  onUnmounted(() => {
    stopWatchingViews();
    stopWatchingEntities();
    stopWatchingCurrentProject();
  });
}
