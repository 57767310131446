/* eslint-disable consistent-return */
import Vue from 'vue';
import api, { STATUSES } from '@/services/api';
import typedDefaults from '@/utils/helpers/typed-defaults';
import { createSingletonLoader, ACTIONS } from '@/store/utils/loader';

export const watchers = [
  // TODO Hybrid - triggers from TKO
  // {
  //   getter: (state) => state.status,
  //   callback: ({ dispatch }, status) => {
  //     if (status === 'unknown') {
  //       dispatch('user/access');
  //     }
  //   },
  //   options: { immediate: true },
  // },
];

export default {
  namespaced: true,
  modules: {
    loader: createSingletonLoader({
      config: {
        reactiveParams: false,
        url: '/me.json',
        params: (state) => ({
          fullprofile: 1,
          getPreferences: 1,
          cleanPreferences: 1,
          getAccounts: 1,
          includeAuth: 1,
          includeClockIn: 1,
          includeTeamIds: true,
          sharedFilter: state.filter.sharedFilterHash,
          // TODO getInitialPage logic
        }),
        silentAjaxErrors: true,
      },
      actions: {
        onLoad({ dispatch, commit }, rs) {
          if (parseInt(rs.data.person.id, 10) <= 0) {
            return dispatch('logout');
          }
          commit('loggedIn', {
            status: 'ok',
            ...rs.data.person,
          });
          commit('preferences/init', { prefs: rs.data.person.preferences, scope: 'user' }, { root: true });
          commit('permissions/init', rs.data.person.permissions, {
            root: true,
          });
          dispatch('getIntegrations');
          dispatch('getProductsAccess');
        },
        [ACTIONS.LOADED_ERROR]({ commit }, error) {
          if (!error.response) {
            commit('loginAgain');
          } else if (error.response.status === STATUSES.CANCELLED) {
            commit('cancelled');
          } else {
            commit('loginAgain');
          }
        },
        // No visible data check, always update the user if the data changes
        // TODO Hybrid
        [ACTIONS.DATA_CHANGE]: ({ dispatch }) => dispatch('hybrid-reload'),
        // eslint-disable-next-line no-undef
        'hybrid-reload': () => app.GetLoggedInUser(),
      },
    }),
  },
  state: {
    status: 'unknown',
    id: 0,
    companyId: 0,
    isClientUser: false,
    inOwnerCompany: false,
    administrator: false,
    siteOwner: false,
    impersonating: false,
    isClockedIn: false,
    isCollaborator: true, // default to true for minimum permissions until we know better
    hasFeatureUpdates: false,
    hasDeskAccount: false,
    hasProjects: false,
    featureUpdatesCount: 0,
    avatarUrl: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    userType: '',
    localization: {
      languageCode: '',
      ellipsis: '',
      startOnSunday: false,
      timeFormat: '',
      dateFormat: '',
      timezoneJavaRefCode: '',
    },
    notifications: {
      soundAlertsEnabled: false,
    },
    auth: {
      token: '',
      timestamp: '',
    },
    accounts: [],
    currentFeatureAnnouncement: {},
    marketoId: 0,
    integrations: {
      googlecalendar: false,
    },
    integrationBetaFeatures: ['googleCalendar'],
    teamIds: [],
    permissions: {
      canAccessCalendar: false,
      canViewSchedule: false,
      canManageSchedule: false,
      isAdminOnAProject: false,
    },

    // Hybrid mapped globals
    isLoggedInUserAdministrator: false,
    loggedInUserId: 0,
    loggedInUserCompanyId: 0,
    ldHash: '',
    productAccess: {
      crm: false,
      desk: false,
      spaces: false,
      chat: false,
    },
    creatingProject: false,
  },
  getters: {
    status: (state) => state.status,
    validUser: (state) => state.status === 'ok',
    fullName: (state) => `${state.firstName} ${state.lastName}`,
    isSiteAdmin: (state) => state.inOwnerCompany && state.administrator,
    isAdmin: (state) => state.administrator,
    isClientUser: (state) => state.isClientUser,
    inOwnerCompany: (state) => state.inOwnerCompany,
    isOwnerAdmin: (state) => state.inOwnerCompany && state.administrator,
    canRequestStatus: (state) => state.inOwnerCompany,
    isAccount: (state) => state.userType === 'account',
    canSetStatus: (state, getters) => getters.isAccount,
    shareLink: (state, getters, { account }) =>
      state.siteOwner || state.administrator
        ? `https://www.teamwork.com/friend/${account.code}`
        : 'https://www.teamwork.com/projects',
    ellipsis: (state) => state.localization.ellipsis || '...',
    languageCode: (state) => state.localization.languageCode || window.navigator.language,
    timezoneJavaRefCode: (state) => state.localization.timezoneJavaRefCode || '',
    timeFormat: (state) => state.localization.timeFormat.replace('tt', 'A').replace(/hh:mm/i, 'HH:mm'),
    dateFormat: (state) => state.localization.dateFormat.replace('dd', 'DD').replace('mm', 'MM'),
    weekString: (state) => (state.localization.startOnSunday ? 'week' : 'isoweek'),
    userType(state) {
      return state.isLoggedInUserAdministrator ? 'administrator' : state.userType;
    },
    canAccessCRM: (state) => state.productAccess.crm,
    canAccessDesk: (state) => state.productAccess.desk,
    canAccessSpaces: (state) => state.productAccess.spaces,
    canAccessChat: (state) => state.productAccess.chat,
    hasSubscribedMarketo: (state) => parseInt(state.marketoId, 10) !== 0,
    hasProjects: (state) => state.hasProjects,
    creatingProject: (state) => state.creatingProject,
  },
  mutations: {
    loggedIn(state, person) {
      Object.assign(state, typedDefaults(state, person));
    },
    loginAgain(state) {
      state.status = 'unauthenticated';
    },
    cancelled(state) {
      state.status = 'cancelled';
    },
    loggedOut(state) {
      state.status = 'logged-out';
    },
    toggleClockIn(state) {
      state.isClockedIn = !state.isClockedIn;
    },
    loggedInUserId(state, payload) {
      state.loggedInUserId = payload;
    },
    loggedInUserCompanyId(state, payload) {
      state.loggedInUserCompanyId = payload;
    },
    isLoggedInUserAdministrator(state, payload) {
      state.isLoggedInUserAdministrator = payload;
    },
    userType(state, payload) {
      state.userType = payload;
    },
    setIntegration(state, payload) {
      state.integrations = {
        ...state.integrations,
        [payload.code]: payload.enabled,
      };
    },
    setHasProjects(state, payload) {
      state.hasProjects = payload;
    },
    // Payload should be an object like:
    // {
    //   spaces: true,
    //   crm: true,
    //   desk: true,
    //   chat: true,
    // }
    // It will set whatever attributes are provided.
    // It's not necessary to pass in all attributes
    setProductAccess(state, payload) {
      state.productAccess = { ...state.productAccess, ...payload };
    },
    setCreatingProject(state, isCreating) {
      state.creatingProject = isCreating;
    },
  },
  actions: {
    getIntegrations({ commit }) {
      return api
        .get('/synthesis/api/v2/integrations/user.json', {
          suppressLoginModal: true,
          noErrorHandling: true,
        })
        .then(({ data }) => {
          const { integrations } = data;

          if (!integrations || !integrations.length) {
            return;
          }

          integrations.forEach((integration) => {
            commit('setIntegration', integration);
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    logout({ commit }) {
      return api.put('/v/1/logout.json').then(() => {
        commit('loggedOut');
      });
    },
    toggleClockIn({ state, dispatch, commit }) {
      return api.post(`/me/clock${state.isClockedIn ? 'out' : 'in'}.json`, { info: '' }).then(() => {
        const viewClockInsLink = `<a href="/#/people/${state.id}/time">${Vue.t('View Clock-Ins')}</a>`;

        commit('toggleClockIn');
        dispatch(
          'notifications/flashes/success',
          state.isClockedIn
            ? `${Vue.t('Clocked In')} - ${viewClockInsLink}`
            : `${Vue.t('Clocked Out')} - ${viewClockInsLink}`,
          { root: true },
        );
      });
    },
    async revertImpersonate({ dispatch }) {
      dispatch('notifications/flashes/success', Vue.t('Reverting'), {
        root: true,
      });

      const { data } = await api.put('/people/impersonate/revert.json');
      const { previousProjectId, impersonatedUserId } = data;

      if (previousProjectId != null && parseInt(previousProjectId, 10) !== 0) {
        return `/projects/${previousProjectId}/people`;
      }
      if (impersonatedUserId != null && !Number.isNaN(parseInt(impersonatedUserId, 10))) {
        return `/people/${impersonatedUserId}`;
      }

      return '/people';
    },
    cancel({ commit }) {
      commit('cancelled');
    },
    // Gets the product access - currently used in account which is used in the product switcher
    // Note: there are currently inconsistent rules in src/store/modules/account.js
    // for product availability such that only CRM and Spaces use these permissions
    getProductsAccess({ commit }) {
      // Get the product access from launchpad
      api
        .get('launchpad/v1/info.json', {
          suppressLoginModal: true,
          noErrorHandling: true,
        })
        .then(({ data }) => {
          const { info } = data;
          if (!info) {
            return;
          }
          if (info.apps) {
            commit('setProductAccess', info.apps);
          }
        });
    },
    setCreatingProject({ commit }, isCreating) {
      commit('setCreatingProject', isCreating);
    },
  },
};
