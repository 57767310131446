import budgetModals from '@/views/project-budgets/modals';
import userRatesModals from '@/views/user-rates/modals';

export default {
  'confirm-modal': () => import(/* webpackChunkName: "frequentModals" */ '@sections/Modals/ConfirmModal'),
  'copy-invite-links-modal': () =>
    import(/* webpackChunkName: "frequentModals" */ '@sections/Modals/CopyInviteLinksModal'),
  'user-change-company-modal': () =>
    import(/* webpackChunkName: "frequentModals" */ '@sections/Modals/UserChangeCompanyModal'),
  'per-user-pricing-quick-set-modal': () =>
    import(/* webpackChunkName: "frequentModals" */ '@sections/Modals/PerUserPricingQuickSet'),
  'people-picker-modal': () => import(/* webpackChunkName: "frequentModals" */ '@sections/Modals/PeoplePickerModal'),
  'hubspot-add-link': () => import('@sections/Modals/HubspotAddLinkModal'),
  'hubspot-new-oauth': () => import('@sections/Modals/HubspotNewAuthModal'),
  'hubspot-disable-warning': () => import('@sections/Modals/HubspotDisableWarning'),
  'hubspot-popover-modal': () => import('@sections/Modals/HubspotPopoverModal'),
  'allocation-modal': () => import('@sections/Modals/AllocationModal'),
  'review-users-modal': () => import('@sections/Modals/ReviewUsers'),
  'review-users-preview-modal': () => import('@sections/Modals/ReviewUsersPreview'),
  'discount-modal': () => import('@sections/Modals/DiscountModal'),
  'explain-roles-modal': () => import('@sections/Modals/ExplainRoles'),
  'unavailable-time-modal': () => import('@sections/Modals/UnavailableTimeModal'),
  'trial-confirmation-modal': () => import('@sections/Modals/TrialConfirmation'),
  'upgrade-plan': () => import('@sections/Modals/UpgradePlan'),
  'save-filter-modal': () => import('@/views/projects-list/sections/SaveFilterModal'),
  'custom-fields-filter-modal': () => import('@/views/projects-list/sections/CustomFieldsFilterModal'),
  'allocation-confirm-modal': () => import('@sections/Modals/AllocationConfirmModal'),
  'manage-users-modal': () => import('@sections/Modals/ManageUsersModal'),
  'downgrade-users-modal': () => import('@sections/Modals/DowngradeUsersModal'),
  'sample-projects-modal': () =>
    import(/* webpackChunkName: "frequentModals" */ '@sections/Modals/SampleProjectsModal'),
  'project-template-preview-modal': () =>
    import(/* webpackChunkName: "frequentModals" */ '@sections/Modals/ProjectTemplatePreviewModal'),
  'copy-project-modal': () => import(/* webpackChunkName: "frequentModals" */ '@sections/Modals/CopyProjectModal'),
  'add-edit-project-wizard-modal': () =>
    import(/* webpackChunkName: "frequentModals" */ '@sections/Modals/AddEditProjectWizardModal'),
  'add-edit-custom-field': () => import(/* webpackChunkName: "frequentModals" */ '@sections/Modals/AddEditCustomField'),
  'attach-task-lists-milestone-modal': () =>
    import(/* webpackChunkName: "frequentModals" */ '@sections/Modals/AttachTaskListsMilestoneModal'),
  'report-builder': () =>
    import(/* webpackChunkName: "frequentModals" */ '@/views/reports/builder/sections/ReportBuilder'),
  'reports-unavailable-time-modal': () =>
    import(/* webpackChunkName: "frequentModals" */ '@/views/reports/utilization/widgets/UnavailableTimeModal'),
  'feature-limit-upgrade-modal': () => import('@sections/Modals/FeatureLimitUpgradeModal'),
  'upgrade-video-modal': () => import('@sections/Modals/UpgradeVideoModal'),
  'edit-recurring-task-modal': () => import('@sections/Modals/EditRecurringTaskModal'),
  'user-group-picker-modal': () =>
    import(/* webpackChunkName: "frequentModals" */ '@sections/Modals/UserGroupPickerModal'),
  'plan-trial-expired-modal': () => import('@sections/Modals/PlanTrialExpiredModal'),
  'plan-trial-feedback-modal': () => import('@sections/Modals/PlanTrialFeedbackModal'),
  'task-history-modal': () => import('@sections/Modals/TaskHistoryModal'),
  'update-time-log-modal': () => import('@sections/Modals/UpdateTimeLogModal'),
  'reports-export-google-auth-modal': () => import('@sections/Modals/ReportsExportGoogleAuthModal'),
  'scheduled-reporting-modal': () => import('@sections/Modals/ScheduledReportingModal'),
  'repeating-interval-modal': () => import('@sections/Modals/RepeatingIntervalModal'),
  'generic-action-confirm-modal': () => import('@sections/Modals/GenericActionConfirmModal'),
  'google-calendar-sync-users-modal': () => import('@sections/Modals/GoogleCalSyncUsersModal'),
  ...budgetModals,
  ...userRatesModals,
};
