<script>
import { computed, defineComponent, shallowRef, watch, watchEffect } from 'vue-demi';
import { provideNotificationsManager } from '@sections/Notifications/useNotificationsManager';
import {
  provideAxios,
  provideBranding,
  provideCurrentAccount,
  provideCurrentUser,
  provideOptimisticUpdates,
  provideRealTimeUpdates,
  provideTWIMSocket,
  useBrandingLoader,
  useCurrentAccountLoader,
  useCurrentUserLoader,
  useRealTimeUpdates,
  useTWIMSocket,
} from '@/api';
import router from '@/router';
import store from '@/store';
import { axiosInstance } from '@/services/api';
import App from '@/components/App';
import AppError from '@/components/AppError';
import { provideAppShell, useAppShell } from '@/platform/composables/useAppShell';
import { provideCurrentProjectState } from '@/platform/composables/useCurrentProject';
import useCurrentProjectLoader from '@/platform/composables/useCurrentProjectLoader';
import { provideHybrid, useHybrid } from '@/platform/composables/useHybrid';
import { provideQuickViews } from '@/platform/composables/useQuickViews';
import { provideCurrentRoute } from '@/platform/composables/useCurrentRoute';
import { provideQuickAddMenuState } from '@/platform/composables/useQuickAddMenuState';
import { provideMorePopoverMenuState } from '@/platform/composables/useMorePopoverMenuState';
import { provideFeatures } from '@/platform/composables/useFeatures';
import useFeaturesLoader from '@/platform/composables/useFeaturesLoader';
import useAppErrorHandler from '@/platform/composables/useAppErrorHandler';
import { provideLaunchDarkly, useLaunchDarkly } from '@/platform/composables/useLaunchDarkly';
import { provideLocalization } from '@/platform/composables/useLocalization';
import { providePreferences } from '@/platform/composables/usePreferences';

import { provideFullContentArea } from '@/platform/composables/useFullContentArea';
import { provideTeamworkFeaturesState } from '@/platform/composables/useTeamworkFeatures';
import { provideTotango } from '@/platform/composables/useTotango';
import { provideNotificationsUnreadCount } from '@/platform/composables/useNotificationsUnreadCount';
import { provideLightspeedBridge } from '@/platform/composables/useLightspeedBridge';
import { provideSidebarPinnedItems } from '@/platform/composables/useSidebarPinnedItems';
import { provideBaseMetrics } from '@/platform/composables/useBaseMetrics';
import { setSocket, getSocketUrl } from '@/scaffolding/socket';

export default defineComponent({
  name: 'Root',
  store,
  router,
  components: {
    App,
    AppError,
  },
  setup() {
    provideAxios(axiosInstance);
    provideOptimisticUpdates();
    provideRealTimeUpdates();

    useAppErrorHandler();

    const {
      item: branding,
      error: brandingError,
      response: brandingResponse,
      inSync: brandingInSync,
    } = useBrandingLoader();
    const {
      item: account,
      error: accountError,
      response: accountResponse,
      inSync: accountInSync,
    } = useCurrentAccountLoader({
      params: { getPreferences: true },
    });
    const {
      item: user,
      error: userError,
      response: userResponse,
      inSync: userInSync,
    } = useCurrentUserLoader({
      params: {
        fullprofile: true,
        getPreferences: true,
        cleanPreferences: true,
        getAccounts: true,
        includeAuth: true,
        includeClockIn: true,
        includeTeamIds: true,
        getDefaultViews: true,
        getDefaultFilters: true,
      },
    });
    const teamworkFeaturesState = useFeaturesLoader({ count: Infinity });
    const { totalCount: featuresTotalCount, error: featuresError } = teamworkFeaturesState;

    provideBranding(branding);
    provideCurrentAccount(account);
    provideCurrentUser(user);
    provideCurrentRoute();
    provideTotango({ account, accountInSync, user, userInSync });
    provideTWIMSocket(getSocketUrl);
    provideTeamworkFeaturesState(teamworkFeaturesState);
    provideLocalization();
    providePreferences({ accountInSync, userInSync });
    provideLaunchDarkly();
    provideFeatures();
    provideAppShell();
    provideLightspeedBridge();
    provideCurrentProjectState(useCurrentProjectLoader());
    provideHybrid();
    provideFullContentArea();
    provideQuickViews();
    provideQuickAddMenuState();
    provideMorePopoverMenuState();
    provideNotificationsUnreadCount();
    provideSidebarPinnedItems();
    provideNotificationsManager();
    provideBaseMetrics();
    const { fetch: fetchNotificationsUnreadCount } = provideNotificationsUnreadCount();
    fetchNotificationsUnreadCount();

    // TODO Handle a user logging in as someone else in a popup
    // which appears when the app gets a 401 error.
    // See teamworkProjects.coffee for more details.

    const { loadHybrid } = useHybrid();

    const socket = useTWIMSocket();
    const { emitFromSocket } = useRealTimeUpdates();
    emitFromSocket(socket);
    watch(socket, setSocket, { immediate: true });

    const { ready: ldClientReady } = useLaunchDarkly();
    const { shouldUseAppShell } = useAppShell();

    watch(ldClientReady, () => {
      store.commit('layout/shouldUseAppShell', shouldUseAppShell);

      if (shouldUseAppShell.value && store.state.layout.isSidebarCollapsed === null) {
        store.commit('layout/isSidebarCollapsed', true);
      }
    });

    const initialDataIsLoaded = shallowRef(false);
    const ready = computed(
      () => ldClientReady.value && featuresTotalCount.value !== undefined && initialDataIsLoaded.value,
    );

    const errorCodes = ['500', '501', '503', '504', '505', '506', '507', '508'];
    const hasError = computed(() =>
      Boolean(
        (brandingError.value &&
          (!brandingError.value.config || brandingError.value.config.cache !== 'only-if-cached') &&
          errorCodes.includes(brandingError.value.status)) ||
          (accountError.value &&
            (!accountError.value.config || accountError.value.config.cache !== 'only-if-cached') &&
            errorCodes.includes(accountError.value.status)) ||
          (userError.value &&
            (!userError.value.config || userError.value.config.cache !== 'only-if-cached') &&
            errorCodes.includes(userError.value.status)) ||
          (featuresError.value &&
            (!featuresError.value.config || featuresError.value.config.cache !== 'only-if-cached') &&
            errorCodes.includes(featuresError.value.status)),
      ),
    );

    const loading = computed(() => !ready.value && !hasError.value);

    watch(loading, () => {
      document.getElementById('preloader').classList.toggle('hidden', !loading.value);
    });

    // Watch and kick off TKO with initial data
    const unwatchInitialData = watch([accountInSync, userInSync, brandingInSync], () => {
      if (accountInSync.value && userInSync.value && brandingInSync.value) {
        window.cfVersionId = accountResponse?.value?.headers?.appversionno;
        initialDataIsLoaded.value = true;

        loadHybrid(accountResponse.value.data, userResponse.value.data, brandingResponse.value.data);

        unwatchInitialData();
      }
    });

    watchEffect(() => {
      if (account.value?.favIcon) {
        const iconLinkEl = document.getElementById('ShortcutIcon');
        if (iconLinkEl) {
          iconLinkEl.href = account.value.favIcon;
        }
        const favIconEl = document.getElementById('favicon');
        if (favIconEl) {
          favIconEl.href = account.value.favIcon;
        }
        const favIconRelEl = document.querySelector("link[rel~='icon']");
        if (favIconRelEl) {
          favIconRelEl.href = account.value.favIcon;
        }
      }
    });

    return {
      ready,
      hasError,
      loading,
    };
  },
});
</script>

<template>
  <div class="Root" :class="{ hidden: loading }">
    <div v-if="ready" class="overflow-x-clip">
      <App />
    </div>
    <AppError v-else-if="hasError" />
  </div>
</template>
