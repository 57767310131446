import Vue from 'vue';
import { isString } from 'lodash-es';
import { match } from 'path-to-regexp';
import api, { API_PREFIXES } from '@/services/api';

/**
 * @typedef {Object} View
 * @property {number} id
 * @property {number} entityId
 * @property {string} entityType
 * @property {string} status
 */

/**
 * @typedef {Object} Recent
 * @property {number} entityId
 * @property {string} entityType
 * @property {string} actionType
 * @property {string} description
 * @property {number} projectId
 */

/**
 * @typedef {Object} RecentPayload
 * @property {Recent} recent
 */

const STATUS = Object.freeze({
  PENDING: 'pending',
  PROCESSING: 'processing',
});

const ENTITY_TYPE = Object.freeze({
  PROJECT: 'project',
  TASK: 'task',
  TASKLIST: 'tasklist',
  MESSAGE: 'message',
  FORM: 'form',
  FILE: 'file',
  NOTEBOOK: 'notebook',
  MILESTONE: 'milestone',
  LINK: 'link',
  INVOICE: 'billingInvoice',
});

// Map entity types as they appear in URLs to the appropriate entity type expected by the API
const urlEntityTypesToViewEntityTypes = {
  projects: ENTITY_TYPE.PROJECT,
  tasks: ENTITY_TYPE.TASK,
  tasklists: ENTITY_TYPE.TASKLIST,
  messages: ENTITY_TYPE.MESSAGE,
  forms: ENTITY_TYPE.FORM,
  files: ENTITY_TYPE.FILE,
  notebooks: ENTITY_TYPE.NOTEBOOK,
  milestones: ENTITY_TYPE.MILESTONE,
  links: ENTITY_TYPE.LINK,
  billing: ENTITY_TYPE.INVOICE,
};

function hashToView(hash) {
  // '|' separated list of entities eg 'projects|tasks|tasklists...'
  const entitiesListForMatcher = Object.keys(urlEntityTypesToViewEntityTypes)
    .map((entity) => entity.toLowerCase())
    .join('|');

  // matches billing routes
  const billingRouteMatcher = match('projects/:projectId/:entityType(billing)\\?id=:entityId(\\d+)');
  // matches routes prefixed with /projects/:projectId eg. 'projects/6/forms/1'
  const projectPrefixedRouteMatcher = match(
    `projects/:projectId/:entityType(${entitiesListForMatcher})/:entityId(\\d+)`,
  );

  // matches routes beginning /:entityType/:entityId
  const defaultRouteMatcher = match(`:entityType(${entitiesListForMatcher})/:entityId(\\d+)(.*)`);

  const routeMatcherResultToView = (matcherResult) => {
    const validMatch = matcherResult && urlEntityTypesToViewEntityTypes[matcherResult.params.entityType];
    if (validMatch) {
      return {
        entityId: parseInt(matcherResult.params.entityId, 10),
        entityType: urlEntityTypesToViewEntityTypes[matcherResult.params.entityType],
      };
    }
    return undefined;
  };

  // try matchers most specific to most generic
  return (
    routeMatcherResultToView(billingRouteMatcher(hash)) ||
    routeMatcherResultToView(projectPrefixedRouteMatcher(hash)) ||
    routeMatcherResultToView(defaultRouteMatcher(hash))
  );
}

function isMatchingView(view1, view2) {
  if (!view1 || !view2) {
    return false;
  }
  return parseInt(view1.entityId, 10) === parseInt(view2.entityId, 10) && view1.entityType === view2.entityType;
}

function isViewProject(view) {
  return view && view.entityType === ENTITY_TYPE.PROJECT;
}

let lastViewedProjectId;

function viewMatchesLastViewedProject(view) {
  return isViewProject(view) && view.entityId === lastViewedProjectId;
}

/**
 * Returns true if given view is of a project and should not be logged as a recent
 *
 * This is required because there are many routes that are considered
 * a project view (e.g project/2/tasks, project/2/board, project/2/table etc) yet
 * we only want to record a view if the project has *changed*
 *
 * @param view
 * @returns {boolean}
 */
function redundantProjectViewGuard(view) {
  if (isViewProject(view)) {
    if (viewMatchesLastViewedProject(view)) {
      return true;
    }
    lastViewedProjectId = view.entityId;
  }
  return false;
}

export default {
  namespaced: true,
  state: {
    views: [],
    entities: {},
  },
  mutations: {
    addEntity(state, { description, entityType, entityId, projectId }) {
      try {
        if (!description || !isString(description)) {
          throw new Error('Cannot add entity - "description" is missing or invalid');
        }

        if (!parseInt(entityId, 10)) {
          throw new Error('Cannot add entity - "entityId" is missing or invalid');
        }

        if (!Object.values(ENTITY_TYPE).includes(entityType)) {
          throw new Error('Cannot add entity - "entityType" is missing or invalid');
        }

        if (!parseInt(projectId, 10)) {
          throw new Error('Cannot add entity - "projectId" is missing or invalid');
        }

        if (!state.entities[entityType]) {
          Vue.set(state.entities, entityType, {});
        }

        Vue.set(state.entities[entityType], entityId, {
          description,
          entityType,
          entityId: parseInt(entityId, 10),
          projectId: parseInt(projectId, 10),
        });
      } catch (error) {
        console.warn(error);
      }
    },

    addView(state, { entityType, entityId }) {
      try {
        // invalid entity types will get blocked at hash parsing

        if (!parseInt(entityId, 10)) {
          throw new Error('Cannot track view - "entityId" is missing or invalid');
        }

        state.views.push({
          // Very unlikely that two views will occur simultaneously leading to an ID conflict
          id: Date.now(),
          entityType,
          entityId: parseInt(entityId, 10),
          status: STATUS.PENDING,
        });
      } catch (error) {
        console.warn(error);
      }
    },

    setStatusForView(state, { id, status }) {
      try {
        if (!parseInt(id, 10)) {
          throw new Error('Cannot set view status - "id" is missing or invalid');
        }

        if (!Array.isArray(state.views)) {
          throw new Error('Cannot set view status - no views exist');
        }

        const index = state.views.findIndex((v) => v.id === id);

        if (index === -1) {
          throw new Error('Cannot set view status - view does not exist');
        }

        if (!Object.values(STATUS).includes(status)) {
          throw new Error('Cannot set view status - "status" is missing or invalid');
        }

        const view = state.views[index];

        Vue.set(state.views, index, {
          ...view,
          status,
        });
      } catch (error) {
        console.warn(error);
      }
    },

    clearView(state, { id }) {
      try {
        if (!parseInt(id, 10)) {
          throw new Error('Cannot clear view - "id" is missing or invalid');
        }

        const viewIndex = state.views.findIndex((v) => v.id === id);

        if (viewIndex === -1) {
          return;
        }

        // Remove the view itself
        Vue.delete(state.views, viewIndex);
      } catch (error) {
        console.warn(error);
      }
    },

    clearViews(state) {
      state.views = [];
    },

    clearEntities(state) {
      state.entities = {};
    },

    clearAll(state) {
      state.views = [];
      state.entities = {};
    },
  },
  actions: {
    logEntity({ commit }, { description, entityType, entityId, projectId }) {
      try {
        if (!description || !isString(description)) {
          throw new Error('Cannot log entity - "description" is missing or invalid');
        }

        if (!parseInt(entityId, 10)) {
          throw new Error('Cannot log entity - "entityId" is missing or invalid');
        }

        if (!Object.values(ENTITY_TYPE).includes(entityType)) {
          throw new Error('Cannot log entity - "entityType" is missing or invalid');
        }

        if (!parseInt(projectId, 10)) {
          throw new Error('Cannot log entity - "projectId" is missing or invalid');
        }

        commit('addEntity', {
          description,
          entityType,
          entityId: parseInt(entityId, 10),
          projectId: parseInt(projectId, 10),
        });
      } catch (error) {
        console.warn(error);
      }
    },

    logView({ commit, state }, { oldHash, newHash }) {
      if (oldHash === newHash) {
        return;
      }

      try {
        const view = hashToView(newHash);

        if (!view) {
          return;
        }

        if (isMatchingView(state.views[state.views.length - 1], view)) {
          return;
        }

        if (redundantProjectViewGuard(view)) {
          return;
        }

        commit('addView', view);
      } catch (error) {
        console.warn(error);
      }
    },

    processRecents({ state, commit }) {
      const promises = [];

      state.views.forEach((view) => {
        if (view.status !== STATUS.PENDING) {
          promises.push(async () => {
            Promise.resolve();
          });
          return;
        }

        const entity = state.entities[view.entityType] && state.entities[view.entityType][view.entityId];

        if (!entity) {
          promises.push(async () => {
            Promise.resolve();
          });
          return;
        }

        const payload = (() => ({
          recent: {
            entityId: parseInt(entity.entityId, 10) || null,
            actionType: 'viewed',
            entityType: entity.entityType,
            projectId: parseInt(entity.projectId, 10) || null,
            description: entity.description,
          },
        }))();

        commit('setStatusForView', { ...view, status: STATUS.PROCESSING });

        promises.push(async () => {
          try {
            await api.post(`${API_PREFIXES.v3}/recency.json`, payload, {
              noErrorHandling: true,
            });
            commit('clearView', view);
          } catch (error) {
            // Reset status back to `pending` (after a timeout) to trigger a retry
            setTimeout(() => {
              commit('setStatusForView', { ...view, status: STATUS.PENDING });
            }, 3000);
          }
        });
      });

      return Promise.all(promises.map((p) => p()));
    },
  },
};
