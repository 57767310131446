import { v3Url } from '@/utils/fetcher';
import useListLoader from '../base/useListLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToMeta({
  data: {
    projectProfitabilities: { projects, ...profitability },
  },
}) {
  return { profitability };
}

function responseToItems({
  data: {
    projectProfitabilities: { projects: profitabilityItems = [] },
    included: { projects = {}, users = {}, companies = {}, projectBudgets = {} } = {},
  },
}) {
  return profitabilityItems.map(({ project: { id }, ...profitability }) => {
    const project = projects[id] || { id };

    project.profitability = profitability;

    if (project.company && companies[project.company.id]) {
      project.company = companies[project.company.id];
    }

    const budgetId = project.timeBudgetId || project.financialBudgetId;
    project.budget = (budgetId && projectBudgets[budgetId]) || null;

    project.owner = (project.ownerId && users[project.ownerId]) || null;

    return project;
  });
}

/**
 * Loads projects and their profitability data from the profitability projects API endpoint.
 */
export default function useProfitabilityProjectsLoader({ params, count, pageSize } = {}) {
  const { state, refresh } = useListLoader({
    url: v3Url('profitability/projects'),
    params,
    count,
    pageSize,
    responseToItems,
    responseToMeta,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'budget' || event.type === 'time' || (event.type === 'project' && event.action === 'rate-set')) {
      refresh(event.projectId);
    }

    if (event.type === 'installation' && event.action === 'rate-set') {
      refresh();
    }
  });

  return state;
}
