import { watch, inject, provide, computed } from 'vue-demi';
import { useCurrentUser } from '@/api';

import useStore from '@/platform/composables/useStore';
import { usePreferences } from '@/platform/composables/usePreferences';
import { usePermissions } from '@/platform/composables/usePermissions';
import { useLightspeedBridge } from '@/platform/composables/useLightspeedBridge';

const sidebarPinnedItemsSymbol = Symbol('sidebarPinnedItems');

export function provideSidebarPinnedItems() {
  const store = useStore();
  const user = useCurrentUser();
  const { sidebarItemState } = usePreferences();
  const { canAccessReportsTab } = usePermissions();

  const { postMessageToLightspeed } = useLightspeedBridge();

  const items = computed(() => {
    const currentUser = user.value;
    const canAccessTime = currentUser.userType === 'account';
    const canAccessEverything = currentUser.userType === 'account';
    const canAccessCalendar = currentUser.administrator || currentUser.permissions.canAccessCalendar;
    const canAccessReports = canAccessReportsTab.value;
    const canAccessPeople =
      (currentUser.administrator || currentUser.inOwnerCompany || currentUser.permissions.canManagePeople) &&
      currentUser.userType === 'account';

    /** @type {Array<[string, boolean]>} */
    const sidebarItems = [
      ['projects', true],
      ['time', canAccessTime],
      ['everything', canAccessEverything],
      ['planning', true],
      ['calendar', canAccessCalendar],
      ['reports', canAccessReports],
      ['people', canAccessPeople],
      ['welcome', true],
    ];

    return sidebarItems;
  });

  const itemsMenu = computed(() => {
    return items.value.filter(([, permission]) => permission).map(([item]) => item);
  });

  const pinnedItems = computed(() => {
    const itemsValue = itemsMenu.value;
    const itemsState = sidebarItemState.value;

    return itemsValue
      .map((key) => {
        return { key, docked: itemsState[key].docked };
      })
      .filter(({ docked }) => docked);
  });

  const isPinned = (itemKey) => computed(() => sidebarItemState.value[itemKey].docked);

  /**
   * @param {string} itemKey
   * @param {boolean} docked
   */
  const setItemDockedState = (itemKey, docked) => {
    sidebarItemState.value = {
      ...sidebarItemState.value,
      [itemKey]: {
        ...sidebarItemState.value[itemKey],
        docked,
      },
    };
  };

  const setPinnedPresets = (pinItems) => {
    const itemsState = sidebarItemState.value;
    const updatedPinnedItems = {};

    pinnedItems.value.forEach((item) => {
      updatedPinnedItems[item.key] = {
        ...item,
        docked: false,
      };
    });

    pinItems.forEach((itemKey) => {
      updatedPinnedItems[itemKey] = {
        ...itemsState[itemKey],
        docked: true,
      };
    });

    return {
      ...sidebarItemState.value,
      ...updatedPinnedItems,
    };
  };

  const pinMultipleItems = (pinItems) => {
    const updatedPinnedItems = setPinnedPresets(pinItems);

    sidebarItemState.value = {
      ...sidebarItemState.value,
      ...updatedPinnedItems,
    };
  };

  const pinItem = (itemKey, itemLabel) => {
    setItemDockedState(itemKey, true);

    if (itemLabel) {
      store.dispatch('notifications/flashes/success', {
        title: `${itemLabel} successfully pinned to the sidebar`,
        opts: {
          undo: () => setItemDockedState(itemKey, false),
        },
      });
    }
  };

  const unPinItem = (itemKey, item) => {
    sidebarItemState.value = {
      ...sidebarItemState.value,
      [itemKey]: {
        ...sidebarItemState.value[itemKey],
        docked: false,
      },
    };
    store.dispatch('notifications/flashes/success', {
      title: `${item} successfully unpinned from the sidebar`,
      opts: {
        undo: () => setItemDockedState(itemKey, true),
      },
    });
  };

  const welcomeDocked = computed(() => Boolean(sidebarItemState.value?.welcome?.docked));
  watch(welcomeDocked, (isDocked) => {
    postMessageToLightspeed(isDocked ? 'twa:welcome-page-docked' : 'twa:welcome-page-dismissed');
  });

  provide(sidebarPinnedItemsSymbol, {
    isPinned,
    pinMultipleItems,
    pinItem,
    unPinItem,
    itemsMenu,
    pinnedItems,
    setPinnedPresets,
  });
}

export function useSidebarPinnedItems() {
  return inject(sidebarPinnedItemsSymbol);
}
